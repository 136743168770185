import { getLive } from '../utils/Storken'
import { api } from './_http'

export const pageview = () => {
  window.fbq('track', 'PageView')
}

export const pixelEvent = (event, content = {}) => {
  const eventId = `${event}_${Date.now()}`
  window.fbq('track', event, getProduct(content), { eventID: eventId })
  postPixel(event, eventId, getProduct(content))
}
export const customPixelEvent = (event, content = [], cost, userData) => {
  const eventId = `${event}_${Date.now()}`
  window.fbq('track', event, getMultipleProducts(content, cost), { eventID: eventId })
  postPixel(event, eventId, getMultipleProducts(content, cost), userData)
}
async function postPixel(event, eventId, customData, userData) {
  // Log for debugging
  console.log('userData', userData)

  // Send all available user data. (Fill in any missing values with an empty string.)
  api.post('/pixel/conversion', {
    eventId,
    eventName: event,
    customData,
    userData: {
      email: userData?.email || '',
      phone: userData?.phone || '',
      name: userData?.name || '',
      il: userData?.il || '',
      ilce: userData?.ilce || '',
      fulladress: userData?.fulladress || '',
      mahalle: userData?.mahalle || ''
    }
  })
}


const getMultipleProducts = (data, cost) => ({
  content_type:'product',
  content_ids:data?.map(({ _id }) => _id),
  contents:data?.map(({ _id, quantity }) => ({ id:_id, quantity })),
  currency:'TRY',
  num_items:data?.length,
  value:cost
})
const getProduct = ({ _id, name, category, packsize, quantity }) => ({
  content_name: name,
  content_category: getLive('categories')?.find(c => c?._id === category)?.name,
  content_ids: [_id],
  content_type: 'product',
  value: packsize?.price,
  currency: 'TRY',
  contents:[{ id:_id, quantity }]
})
